<template>
    <div>
        <b-col v-if="this.investmentsLiquidations.length > 0">
            <b-table             
                ref="selectableTable"
                :fields="fieldsViewLiquidations" 
                :items="investmentsLiquidations"                             
                responsive                
                selectable
                class="mb-0"
                :select-mode="selectMode"
                @row-selected="onRowSelected"                
                >
                <template #cell(liquidationDate)="data">                                
                    {{((data.item.liquidationDate))}}
                </template>  

                <template #cell(liquidationType)="data">                                
                    {{data.item.liquidationType}}
                </template>  

                <template #cell(amountReceived)="data">
                    ${{parseFloat(data.item.amountReceived).toFixed(2).toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")}}
                </template>  

                <template #cell(messageToInvestors)="data">                                
                    {{data.item.messageToInvestors}}
                </template> 
            </b-table>
        </b-col>
       
        <b-row v-else class="justify-content-center mt-1" style="text-align:center">
            <b-col xl="6" md="6" lg="6" >
                <b-alert variant="primary" show>
                    <div class="alert-body">
                        <span><strong>Este Proyecto aún no tiene liquidaciones</strong></span>
                    </div>
                </b-alert>
            </b-col>
        </b-row>
    </div>
</template>

<script>
    import axios from '@axios'
    import {
        BTable, BCol, BAlert, BRow 
    } from 'bootstrap-vue'

    export default {
        name:"LiquidationsComponent",
        props:{
            investmentID: null
        },
        components: { 
            BTable, 
            BCol,
            BRow,
            BAlert
        },
        data(){
            return{
                selectMode: 'single',     
                fieldsViewLiquidations: [
                        {key: 'liquidationDate', label: 'Fecha', thStyle: { width: "20%" }},
                        {key: 'liquidationType', label: 'Tipo Liquidación', thStyle: { width: "20%" }},
                        {key: 'amountReceived', label: 'Monto Recibido', thStyle: { width: "20%" }},                        
                        {key: 'messageToInvestors', label: 'Mensaje a Neoganaderos', thStyle: { width: "40%" }}
                    ],
                createDate: null,
                liquidationType: null,
                amountReceived: null,
                description: null,
                messageToInvestors: null,
                investmentsLiquidations: [{}],
                }
            },
            created(){
                this.getInvestmentsLiquidations();
            },

            methods:{
                getInvestmentsLiquidations(){
                    axios.get('/Liquidation/GetAllInvestmentsLiquidations', { params: { investmentID: this.investmentID}})
                    .then(response =>{
                        this.investmentsLiquidations = response.data.items
                        if(this.investmentsLiquidations.length > 0){
                            this.investmentsLiquidations.forEach(item => {                                
                                item.liquidationDate = item.projectLiquidation.liquidationDate.slice(0,10);
                                item.amountReceived = item.investmentLiquidation.amountReceived;
                                item.liquidationType = item.projectLiquidation.liquidationType;
                                item.messageToInvestors = item.projectLiquidation.messageToInvestors                        
                            });
                        }
                    })
                    .catch(error => {
                    this.processing = false;
                        if(error.response){
                        this.$bvToast.toast(error.response.data, { title: 'Error', variant: 'danger', solid: true, })
                        } else {
                        this.$bvToast.toast(JSON.stringify(error), { title: 'Error', variant: 'danger', solid: true, })
                        }
                    })
                },
                onRowSelected(items) {
                    this.userSelected = items[0];  
                }
        }
    }

</script>